<template>
    <div class="p-3">
        <h2>Izjava o zasebnosti podatkov </h2>
        SMRK, Mojca Tomišić, s.p., spletna tržnica (v nadaljevanju: SMRK), spoštuje vašo pravico do zasebnosti in se
        zavezuje, da bodo zbrani osebni podatki na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>
        uporabljeni v skladu s to Izjavo o zasebnosti. SMRK je upravljalec Prleške spletne tržnice in spletnega portala
        <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>.
        <h3 class="mt-3">Podatki</h3>
        Za potrebe poslovanja portal <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> zbira naslednje osebne
        podatke registriranih uporabnikov:
        <ul>
            <li>uporabniško ime,</li>
            <li>geslo,</li>
            <li>elektronski naslov,</li>
            <li>ostale podatke, ki jih uporabnik vnese v obrazec na spletnem portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>.
            </li>
        </ul>
        Vsi podatki, razen uporabniškega imena, gesla in elektronskega naslova, so neobvezni. Priporočamo vsaj vnos
        telefonske številke, da vas lahko ob izjemnih situacijah obvestimo. Ob morebitni dostavi na dom je podatek o
        naslovu obvezen.
        <br>
        Upravljalec portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> si pridržuje pravico, da
        neprimerno izbrana uporabniška imena (uporaba vulgarnih besed, izražanje nasilja, imena javnih osebnosti ipd.)
        spremeni brez strinjanja uporabnika. V tem primeru bo uporabnik o spremembi obveščen po e-pošti.
        <h3 class="mt-3">Obveščanje</h3>
        Z registracijo uporabnik soglaša, da bo prejemal obvestila, ki so vezana na njegovo naročilo, in sistemska
        obvestila. Ob registraciji lahko tudi označi, da soglaša s prejemanjem občasnih obvestil (Novičke), to je
        obvestil o tedenski ponudbi, predstavitvah novih produktov in ponudnikov in o morebitnih aktualnih dogodkih ter
        nagradnih igrah, pa tudi izobraževalnih vsebin, povezanih s pridelavo in predelavo hrane ter z ostalimi
        produkti, ponujanimi na Prleški spletni tržnici.
        <h3 class="mt-3">Registracija</h3>
        Vsak uporabnik se strinja s Splošnimi pogoji poslovanja in mora biti polnoleten, kar tudi potrdi ob registraciji
        s kljukico pred obema trditvama.
        Vsak uporabnik dovoli uporabo svojih zgoraj navedenih osebnih podatkov za potrebe izpolnitve naročila in ostalo,
        z naročilom povezano in potrebno komunikacijo ter za namene neposrednega trženja in proučevanja nakupnih navad.
        <b>Za točnost podatkov, ki jih uporabnik vnese, upravljalec portala ne odgovarja.</b>
        <h3 class="mt-3">Preklic registracije</h3>
        Registrirani uporabniki lahko kadarkoli prenehajo uporabljati spletno trgovino in lahko prekličejo svojo
        registracijo. To storijo tako, da sporočijo preklic s pisno izjavo upravljalcu spletnega portala ali preko
        elektronske pošte, navedene med Kontakti. Pred podajo izjave o preklicu mora uporabnik upravljalcu portala <a
        href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> poravnati vse še neporavnane obveznosti iz naslova
        opravljenih nakupov v spletni trgovini. Upravljalec portala bo zaupnost osebnih podatkov in zasebnost
        uporabnikov spletne trgovine varoval tudi v primeru preklica registracije.
        <h3 class="mt-3">Zasebnost in zaščita osebnih podatkov</h3>
        Upravljalec portala se obvezuje, da bo vse osebne podatke obiskovalcev/uporabnikov/kupcev ter podatke o nakupnih
        navadah kupcev uporabljal in hranil v skladu z določili Zakona o varstvu osebnih podatkov.
        <br>
        Upravljalec portala <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> neposredno zbira informacije, ki
        jih uporabniki vnesejo na portalu. Upravljalec teh informacij ne razkriva nepooblaščenim tretjim osebam. Z
        izjemo obvestil, ki so povezana z naročilom, upravljalec ne kontaktira svojih kupcev, razen v primeru izrecnega
        strinjanja s strani kupca.
        <br>
        Spletne strani za naročilo izdelkov ter prenosi so zaščiteni in ustrezno tehnično oz. elektronsko varovani.
        Upravljalec portala ne odgovarja za morebitno krajo podatkov.
        <br>
        Upravljalec portala za zakonsko določen čas hrani IP-naslove vseh obiskovalcev portala <a
        href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> ter ostale podatke, ki jih posreduje brskalnik, pri
        registriranih uporabnikih pa še: uporabniško ime, geslo in e-poštni naslov ter ostale vpisane podatke s strani
        uporabnika, čas in datum registracije in arhiv komunikacije z upravljalcem portala ter zgodovino aktivnosti ter
        nakupov ipd., opravljenih na portalu.
        <br>
        Upravljalec portala bo osebne podatke uporabil izključno za potrebe izpolnitve naročila (pošiljanje
        informativnega gradiva, ponudb, računov, vse v zvezi z naročilom) in ostalo, povezano z naročilom in potrebno
        komunikacijo.
        <br>
        Upravljalec portala bo, ob predhodnem strinjanju s piškotki, osebne podatke uporabljal tudi za lastne namene
        neposrednega trženja in proučevanja nakupnih navad na podlagi podatkov o kupljenih izdelkih in posledično
        ciljnega trženja. Uporabnik lahko kadarkoli pisno zahteva, da upravljalec portala trajno ali začasno preneha
        uporabljati osebne podatke in podatke o nakupovanju za prej navedene namene.
        <br>
        Ker obstajajo na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> določene povezave na druge
        spletne strani, ki niso v nikakršni povezavi z družbo SMRK, ne prevzemamo nobene odgovornosti za zaščito
        podatkov na teh spletnih straneh.
        <br>
        Uporabnik baze podatkov je podjetje SMRK, Mojca Tomišić, s.p., spletna tržnica, in v podjetju pooblaščene osebe.
        Za potrebe izvajanja programa, analize in različnih poizvedb lahko podjetje SMRK sodeluje z zunanjimi partnerji,
        s katerimi ima sklenjene ustrezne pogodbe o varovanju/obdelovanju osebnih podatkov.
        <br>
        Uporabnikovi/kupčevi podatki v nobenem primeru ne bodo predani nepooblaščenim osebam oz. se ne bodo uporabljali
        v nasprotju z njihovim namenom, razen če se bo uporabnik/kupec izrecno in ustrezno strinjal z drugimi izrecno
        opredeljenimi nameni uporabe teh podatkov.
        <br>
        Za varovanje osebnih podatkov je odgovoren tudi uporabnik sam, in sicer tako, da poskrbi za varnost svojega
        uporabniškega imena in gesla ter ustrezno programsko (protivirusno) zaščito svojega informacijskega sistema oz.
        elektronske naprave.
        <br>
        V skladu z Zakonom o varstvu osebnih podatkov ima kupec pravico do dostopa in popravka svojih osebnih podatkov,
        ki jih hrani upravljalec portala. Podatke lahko popravi v svojem uporabniškem profilu na <a
        href="www.prlekijanadlani.si">www.prlekijanadlani.si</a> ali s pisnim zahtevkom ponudniku, kateremu mora
        predložiti dokazilo o svoji identiteti in naslovu.
        <br>
        V primeru morebitnih vprašanj v zvezi z varstvom osebnih podatkov na portalu <a href="www.prlekijanadlani.si">www.prlekijanadlani.si</a>
        je upravitelj portala dosegljiv na kontaktnih naslovih, ki so objavljeni <b-link href="#/contacts">tukaj</b-link>.
    </div>
</template>

<script lang="">
export default {
  name: "Privacy",
  components: {},
  props: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
  computed: {},
  filters: {},
  watch: {}
}



</script>

<style scoped>

</style>
